
import './index.css';
import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Localhost from './Localhost';
import Homepage from './Homepage';
function App() {
  return (
    <Router>
   
     <Routes>
      <Route path = "/" element = {<Homepage/>}></Route>
     <Route path="/localhost" element={<Localhost/>} />

     </Routes>
    </Router>
  );
}

export default App;
{/* <Router basename="/FarhanBookstoreReactSession">
            <Routes>
                <Route path="/" element={<Mainpage categories = {categories} category = { category} setCategory = {setCategory}  />} />

                <Route path="/categories" element={<Categories setCurrCategory  = {setCurrCategory }/>}>

                    <Route path=":catName" element={<Categories  setCurrCategory = {setCurrCategory}/>}/>



                    </Route>
                <Route path="/cart" element={<Cart currCategory = {currCategory}/>}/>
                <Route path="/checkout" element={<Checkout/>}/>


            </Routes>
        </Router> */}